.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000000;
}

.links-group a {
  margin-right: 10px;
}

#diagram-container {
  width: 100%;
}

.card-img-top {
  padding: 10px;
  height: 200px;
  width: auto;
  object-fit: contain;
}

.notification-toast {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.notification-toast .d-flex {
  align-items: center;
}

.toast-close {
  border: none;
  height: 30px;
  background: none;
}

.offcanvas-start {
  width: 50%;
}

.offcanvas-body {
  overflow-y: hidden;
}

.popover {
  max-width: 800px;
}

.nav-separator {
  line-height: 35px;
}

#faqs, #documentation {
  margin-bottom: 300px;
}

nav {
  background-color: #ae005b !important;
  margin-bottom: 0 !important;
}

nav .nav-link, nav .navbar-brand {
  color: #fff !important;
}

#login, #password-reset {
  max-width: 500px;
}

.no-focus:focus {
  outline: 0;
  box-shadow: none;
}

.hero-pic {
  transform: translateY(4px);
  max-width: 80%;
}

#home .col {
  margin-bottom: 1.5rem;
}

#faqs h3 {
  margin-top: 40px;
  margin-bottom: 10px;
}

#home .card-img-top {
  background-color: #fcfcfc;
}

#home .card-body {
  border-top: 1px solid rgba(0, 0, 0, .125)
}

#display-logo {
  max-width: 450px;
}

#header-logo {
  max-height: 35px;
}

img#mission {
  width: 100%;
}

.nav-separator {
  color: gray;
}

.navigator input {
  transition: width .5s;
  width: 200px;
}

.navigator input.focus {
  width: 400px;
}

.navigator #navigate-to {
  width: 400px !important;
}

#save-tooltip .tooltip-inner {
  max-width: none;
}

.align-right .dropdown-menu {
  left: auto;
  right: 0;
}

.profile-dropdown .dropdown-menu {
  padding-top: 0;
}

.profile-dropdown .dropdown-item p {
  margin: 0;
}

.profile-dropdown .dropdown-item.disabled {
  color: unset;
  background-color: #f2f2f2;
  margin-bottom: 10px;
}

/* Custom Styles for Landing and About pages */

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

/*
 * Custom translucent site header
 */

.site-header {
  background-color: rgba(0, 0, 0, .85);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}
.site-header a {
  color: #8e8e8e;
  transition: color .15s ease-in-out;
}
.site-header a:hover {
  color: #fff;
  text-decoration: none;
}

/*
 * Dummy devices (replace them with your own or something else entirely!)
 */

.product-device {
  position: absolute;
  right: 10%;
  bottom: -30%;
  width: 300px;
  height: 540px;
  background-color: #333;
  border-radius: 21px;
  transform: rotate(30deg);
}

.product-device::before {
  position: absolute;
  top: 10%;
  right: 10px;
  bottom: 10%;
  left: 10px;
  content: "";
  background-color: rgba(255, 255, 255, .1);
  border-radius: 5px;
}

.product-device-2 {
  top: -25%;
  right: auto;
  bottom: 0;
  left: 5%;
  background-color: #e5e5e5;
}


/*
 * Extra utilities
 */

.flex-equal > * {
  flex: 1;
}
@media (min-width: 768px) {
  .flex-md-equal > * {
    flex: 1;
  }
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.feature-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  margin-bottom: 1rem;
  font-size: 2rem;
  color: #fff;
  border-radius: .75rem;
}

.icon-link {
  display: inline-flex;
  align-items: center;
}
.icon-link > .bi {
  margin-top: .125rem;
  margin-left: .125rem;
  transition: transform .25s ease-in-out;
  fill: currentColor;
}
.icon-link:hover > .bi {
  transform: translate(.25rem);
}

.icon-square {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  border-radius: .75rem;
}

.rounded-4 { border-radius: .5rem; }
.rounded-5 { border-radius: 1rem; }

.text-shadow-1 { text-shadow: 0 .125rem .25rem rgba(0, 0, 0, .25); }
.text-shadow-2 { text-shadow: 0 .25rem .5rem rgba(0, 0, 0, .25); }
.text-shadow-3 { text-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, .25); }

.card-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
