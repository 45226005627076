#flow-label {
  margin: 20px;
}

.resizer {
  cursor: nwse-resize;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

svg {
  border: 1px solid lightgray;
  background-color: #fff;
}

svg.short {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#diagram-svg {
  margin: 0 0 30px;
  padding: 40px;
  overflow: auto;
  background: url('diagram-bg.png');
  height: calc(100vh - 215px);
  text-align: center;
  position: relative;
}

.fullscreen-enabled #diagram-svg {
  margin: 0 -30px;
  height: calc(100vh - 90px);
}

#diagram-svg #step-card-cont {
  text-align: left;
  position: absolute;
  display: none;
  z-index: 10;
  width: 400px;
  transform: translate(-15px, 15px);
}